import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { Button, ButtonAnchor, ButtonGroup, Alert } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "button"
    }}>{`Button`}</h1>
    <Playground __position={0} __code={'<Button>Button</Button>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      ButtonAnchor,
      ButtonGroup,
      Alert,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button mdxType="Button">Button</Button>
    </Playground>
    <h2 {...{
      "id": "variants"
    }}>{`Variants`}</h2>
    <Playground __position={1} __code={'<ButtonGroup>\n  <Button variant=\"primary\">Primary</Button>\n  <Button variant=\"secondary\">Secondary</Button>\n  <Button variant=\"inverted\">Inverted</Button>\n  <Button variant=\"link\">Link</Button>\n  <Button variant=\"reset\">Reset</Button>\n</ButtonGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      ButtonAnchor,
      ButtonGroup,
      Alert,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup mdxType="ButtonGroup">
    <Button variant="primary" mdxType="Button">Primary</Button>
    <Button variant="secondary" mdxType="Button">Secondary</Button>
    <Button variant="inverted" mdxType="Button">Inverted</Button>
    <Button variant="link" mdxType="Button">Link</Button>
    <Button variant="reset" mdxType="Button">Reset</Button>
  </ButtonGroup>
    </Playground>
    <h2 {...{
      "id": "small"
    }}>{`Small`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Button small>Small button</Button>
`}</code></pre>
    <Playground __position={2} __code={'<ButtonGroup>\n  <Button variant=\"primary\" small>\n    Primary\n  </Button>\n  <Button variant=\"secondary\" small>\n    Secondary\n  </Button>\n  <Button variant=\"inverted\" small>\n    Inverted\n  </Button>\n  <Button variant=\"link\" small>\n    Link\n  </Button>\n  <Button variant=\"reset\" small>\n    Reset\n  </Button>\n</ButtonGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      ButtonAnchor,
      ButtonGroup,
      Alert,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup mdxType="ButtonGroup">
    <Button variant="primary" small mdxType="Button">
      Primary
    </Button>
    <Button variant="secondary" small mdxType="Button">
      Secondary
    </Button>
    <Button variant="inverted" small mdxType="Button">
      Inverted
    </Button>
    <Button variant="link" small mdxType="Button">
      Link
    </Button>
    <Button variant="reset" small mdxType="Button">
      Reset
    </Button>
  </ButtonGroup>
    </Playground>
    <h2 {...{
      "id": "disabled"
    }}>{`Disabled`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Button disabled>Disabled button</Button>
`}</code></pre>
    <Playground __position={3} __code={'<ButtonGroup>\n  <Button variant=\"primary\" disabled>\n    Primary\n  </Button>\n  <Button variant=\"secondary\" disabled>\n    Secondary\n  </Button>\n  <Button variant=\"inverted\" disabled>\n    Inverted\n  </Button>\n  <Button variant=\"link\" disabled>\n    Link\n  </Button>\n  <Button variant=\"reset\" disabled>\n    Reset\n  </Button>\n</ButtonGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      ButtonAnchor,
      ButtonGroup,
      Alert,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup mdxType="ButtonGroup">
    <Button variant="primary" disabled mdxType="Button">
      Primary
    </Button>
    <Button variant="secondary" disabled mdxType="Button">
      Secondary
    </Button>
    <Button variant="inverted" disabled mdxType="Button">
      Inverted
    </Button>
    <Button variant="link" disabled mdxType="Button">
      Link
    </Button>
    <Button variant="reset" disabled mdxType="Button">
      Reset
    </Button>
  </ButtonGroup>
    </Playground>
    <h2 {...{
      "id": "shape"
    }}>{`Shape`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Button shape="cool">Cool button</Button>
`}</code></pre>
    <Playground __position={4} __code={'<ButtonGroup>\n  <Button variant=\"primary\" shape=\"cool\">\n    Primary\n  </Button>\n  <Button variant=\"secondary\" shape=\"cool\">\n    Secondary\n  </Button>\n  <Button variant=\"inverted\" shape=\"cool\">\n    Inverted\n  </Button>\n  <Button variant=\"link\" shape=\"cool\">\n    Link\n  </Button>\n  <Button variant=\"reset\" shape=\"cool\">\n    Reset\n  </Button>\n</ButtonGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      ButtonAnchor,
      ButtonGroup,
      Alert,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup mdxType="ButtonGroup">
    <Button variant="primary" shape="cool" mdxType="Button">
      Primary
    </Button>
    <Button variant="secondary" shape="cool" mdxType="Button">
      Secondary
    </Button>
    <Button variant="inverted" shape="cool" mdxType="Button">
      Inverted
    </Button>
    <Button variant="link" shape="cool" mdxType="Button">
      Link
    </Button>
    <Button variant="reset" shape="cool" mdxType="Button">
      Reset
    </Button>
  </ButtonGroup>
    </Playground>
    <h2 {...{
      "id": "buttonanchor"
    }}>{`ButtonAnchor`}</h2>
    <Playground __position={5} __code={'<ButtonGroup>\n  <ButtonAnchor variant=\"primary\" href=\"#\">\n    Primary\n  </ButtonAnchor>\n  <ButtonAnchor variant=\"secondary\" href=\"#\">\n    Secondary\n  </ButtonAnchor>\n  <ButtonAnchor variant=\"inverted\" href=\"#\">\n    Inverted\n  </ButtonAnchor>\n  <ButtonAnchor variant=\"link\" href=\"#\">\n    Link\n  </ButtonAnchor>\n  <ButtonAnchor variant=\"reset\" href=\"#\">\n    Reset\n  </ButtonAnchor>\n</ButtonGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      ButtonAnchor,
      ButtonGroup,
      Alert,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup mdxType="ButtonGroup">
    <ButtonAnchor variant="primary" href="#" mdxType="ButtonAnchor">
      Primary
    </ButtonAnchor>
    <ButtonAnchor variant="secondary" href="#" mdxType="ButtonAnchor">
      Secondary
    </ButtonAnchor>
    <ButtonAnchor variant="inverted" href="#" mdxType="ButtonAnchor">
      Inverted
    </ButtonAnchor>
    <ButtonAnchor variant="link" href="#" mdxType="ButtonAnchor">
      Link
    </ButtonAnchor>
    <ButtonAnchor variant="reset" href="#" mdxType="ButtonAnchor">
      Reset
    </ButtonAnchor>
  </ButtonGroup>
    </Playground>
    <h2 {...{
      "id": "with-shadows"
    }}>{`With shadows`}</h2>
    <Playground __position={6} __code={'<Button boxShadow=\"md\" variant=\"inverted\">\n  Shadow button\n</Button>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      ButtonAnchor,
      ButtonGroup,
      Alert,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button boxShadow="md" variant="inverted" mdxType="Button">
    Shadow button
  </Button>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={Button} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      